<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="h4 semiBold">Cotizaciones y Ventas en proceso </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="light br-8" elevation="0">
          <v-card-text>
            <div class="d-flex align-center justify-space-between g3">
              <div class="filter">
                <LabelComponent text="Tipo de documento" />
                <v-select
                  v-model="selectedTipoDocumento"
                  :items="tipo_documentos"
                  item-text="descripcion"
                  item-value="codigo"
                  outlined
                  dense
                  clearable
                  append-icon="mdi-chevron-down"
                  single-line></v-select>
              </div>
              <div class="filter">
                <LabelComponent text="Ingrese n° documento" />
                <v-text-field
                  class="mx-1"
                  v-model="textSearch.value"
                  outlined
                  clearable
                  dense></v-text-field>
              </div>
              <div class="filter">
                <LabelComponent text="Plazo" />

                <v-select
                  v-model="plazoField.value"
                  :items="plazoField.options"
                  item-text="text"
                  item-value="value"
                  :label="plazoField.label"
                  :placeholder="plazoField.placeholder"
                  persistent-hint
                  return-object
                  outlined
                  dense
                  clearable
                  append-icon="mdi-chevron-down"
                  single-line></v-select>
              </div>
              <div class="filter">
                <LabelComponent text="Etapa" />
                <v-select
                  :items="steps"
                  v-model="estadoField.value"
                  :label="estadoField.label"
                  :placeholder="estadoField.placeholder"
                  item-text="text"
                  item-value="value"
                  persistent-hint
                  outlined
                  dense
                  clearable
                  append-icon="mdi-chevron-down"
                  single-line></v-select>
              </div>

              <div>
                <v-btn
                  color="primary"
                  class="text-none mx-4"
                  width="140"
                  @click="filter()"
                  :disabled="loading"
                  rounded>
                  <Icon name="filter_alt" class="text-white" />
                  <span class="ml-2">Filtrar</span>
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- DATOS -->
    <v-data-table
      :headers="headers"
      :items="Cotizaciones"
      :loading="loading"
      :page="pagination.page"
      no-data-text="No hay cotizaciones para mostrar"
      no-results-text="No se han encontrado cotizaciones"
      loading-text="Cargando cotizaciones..."
      :footer-props="{
        'items-per-page-text': 'Mostrar por página:',
        'items-per-page-options': [5, 10, 20, 30, 50, 100],
      }"
      @pagination="setPaginationFromTable($event)">
      <template #item.fecha_creacion="{ item }">
        {{ item.fecha_creacion | formatDate }}
      </template>

      <template #item.numero_documento="{ item }">
        {{ item.numero_documento | rut }}
      </template>

      <template #item.estado="{ item }">
        <span class="h6 semiBold">
          {{
            steps.find((e) => e.value === item.estado)?.text ?? "Desconocido"
          }}
        </span>
      </template>

      <template #item.plazo_retoma="{ item }">
        <v-badge
          left
          dot
          :color="getRetomaColor(getRetomaPlazoNumber(item.plazo_retoma))">
        </v-badge>
        <span class="ml-1">
          {{ getRetomaPlazoNumber(item.plazo_retoma) }}
          Días
        </span>
      </template>

      <template #item.action="{ item }">
        <v-btn
          color="primary"
          text
          class="text-none"
          small
          @click="Retomar(item)"
          :disabled="loading"
          :loading="item.loading">
          Completar
          <v-icon size="14" class="ml-1">mdi-arrow-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { resumeQuote } from "@/Mixins/resumeQuote.js";
import { PaginationMixin } from "@/Mixins/PaginationMixin.js";
// import TextField from "@/components/Inputs/TextField";
import LabelComponent from "@/components/Inputs/Label.vue";
import Icon from "@/components/Icon/Icon.vue";

export default {
  name: "ListadoDeCotizaciones",
  components: { LabelComponent, Icon },
  mixins: [resumeQuote, PaginationMixin],
  data() {
    return {
      textsearch: "",
      textSearch: {
        placeholder: "Escriba un numero de cotización o RUT..",
        label: "Buscar por Nro de cotización o RUT",
        value: "",
        icon: "mdi-magnify",
      },
      loading: false,
      tipo_documentos: JSON.parse(sessionStorage.getItem("tipo_documentos")),
      selectedTipoDocumento: null,

      // TABLE HEADERS
      headers: [
        {
          text: "Nº Cotización",
          value: "numero_cotizacion",
          align: "left",
        },
        {
          text: "Fecha cotización",
          value: "fecha_creacion",
          align: "left",
        },
        {
          text: "Tipo documento",
          value: "fecha_creacion",
          align: "left",
        },
        {
          text: "N° documento",
          value: "numero_documento",
          align: "left",
        },

        { text: "Asegurado", value: "cliente", align: "left" },
        {
          text: "Tipo de Seguro",
          value: "categoria_descripcion",
          align: "left",
        },
        {
          text: "Plazo de retoma",
          value: "plazo_retoma",
          align: "left",
        },
        { text: "Etapa", value: "estado", align: "left" },
        {
          text: "Acción",
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
      // SelectField PLAZO
      plazoField: {
        label: "Buscar por plazo",
        options: [
          {
            text: "1 a 15 Días",
            value: "1-15",
          },
          {
            text: "16 a 30 Días",
            value: "16-30",
          },
          {
            text: "31 a 60 Días",
            value: "31-60",
          },
          {
            text: "60 Días o más",
            value: "61-",
          },
        ],
        placeholder: "Seleccione un plazo",
        value: "",
      },
      // SelectField Estado
      estadoField: {
        label: "Estado",
        placeholder: "Seleccione un estado",
        value: "",
      },
    };
  },

  watch: {
    async country(val) {
      if (val) {
        this.tipo_documentos = await this.getTiposDocumentos(this.country);
      }
    },
  },

  methods: {
    ...mapActions("Quot", ["GetCotizaciones"]),
    ...mapActions("Internationalization", ["getTiposDocumentos"]),

    setup() {
      this.loading = true;
      let params = {
        limite: this.pagination.limit,
        pagina: this.pagination.page,
      };
      if (this.$route.query.rut) {
        params.rut = this.$route.query.rut;
      }
      if (this.estadoField.value) {
        params.estado = this.estadoField.value;
      }
      if (this.textSearch.value) {
        params.numero_documento = this.textSearch.value;
      }
      if (this.selectedTipoDocumento) {
        params.tipo_documento = this.selectedTipoDocumento;
      }
      if (this.plazoField.value) {
        params.vigencia = this.plazoField.value.value;
      }

      this.GetCotizaciones(params)
        .then((response) => {
          const pagination = response?.pagination;
          this.setOrResetPagination(pagination);
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },

    getRetomaPlazoNumber(retomaList) {
      return Math.max.apply(
        Math,
        retomaList.map(function (o) {
          return o.plazo_retoma;
        })
      );
    },
    getRetomaColor(n) {
      let num = n;
      let color = "";
      if (typeof n === "string") {
        num = parseInt(n);
      }
      if (num > 0 && num < 4) color = "#EE2E22";
      else if (num >= 4 && num < 7) color = "#F2C94C";
      else if (num >= 7) color = "#27AE60";
      else color = "primary";
      return color;
    },
  },
  computed: {
    ...mapGetters("Users", ["getUserInfo"]),
    ...mapGetters("Quot", ["Cotizaciones"]),
    country() {
      return this.$store.getters.getCountry;
    },
    steps() {
      return [
        {
          text: "Falta Información",
          value: "FALTA",
        },
        {
          text: "Abierta",
          value: "ABIERTA",
        },
        {
          text: "Seleccionada",
          value: "SELECCIONADA",
        },
        {
          text: "Cerrada",
          value: "CERRADA",
        },
      ];
    },
  },
};
</script>

<style scoped src="../../../assets/CSS/SimpleTable.css"></style>

<style scoped>
.filter {
  max-width: 212px;
}
</style>
